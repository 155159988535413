<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <v-row>
          <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        </v-row>
        <v-row>
          <v-col cols="9">
            <PageHeader :title="`${this.title}: ${this.titleTabla}`" />
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <v-col cols="4" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Fecha de consulta (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigencia = parseDateToIso(
                              fechaVigenciaSelected
                            )
                          "
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="
                          fechaVigenciaSelected = formatDate(fechaVigencia)
                        "
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-tooltip bottom max-width="20%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="primary--text" v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>La fecha se utilizará para agente prestadores</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="agente-autocomplete"
                      v-model.trim="agenteSelected"
                      :items="agentes"
                      @change="
                        agenteSelected == null || agenteSelected == ''
                          ? $refs['origen-autocomplete'].reset() ||
                            $refs['convenio-autocomplete'].reset() ||
                            $refs['planes-autocomplete'].reset() ||
                            $refs['prestadores-autocomplete'].reset()
                          : null
                      "
                      :search-input.sync="agentesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :filter="customFilterAgente"
                      outlined
                      clearable
                      hide-no-data
                      hint="Ingrese código o nombre de agente"
                      return-object
                      dense
                      :cache-items="banderaTrue ? banderaTrue : !banderaTrue"
                      autocomplete="off"
                      label="Agente de pago (*)"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="agentesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Origen -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="origen-autocomplete"
                      v-model.trim="origenSelected"
                      :disabled="
                        agenteSelected == null ||
                          allOrigen == true ||
                          agrupacionSelected != null
                      "
                      :items="origenes"
                      :search-input.sync="origenesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="origenChange(origenSelected)"
                      :filter="customFilterOrigen"
                      outlined
                      hide-no-data
                      hint="Ingrese código o nombre de origen"
                      clearable
                      dense
                      autocomplete="off"
                      label="Origen"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="origenesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Grupo origen -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      v-model="agrupacionSelected"
                      :items="itemsAgrupacion"
                      label="Agrupación"
                      item-text="value"
                      :disabled="
                        allOrigen == true ||
                          agenteSelected == null ||
                          origenSelected != null
                      "
                      item-value="id"
                      outlined
                      @change="setGruposByAgrupaciones"
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="pb-0 py-0">
                    <v-autocomplete
                      class="p-0"
                      v-model="grupoSelected"
                      :items="itemsGrupo"
                      label="Grupo"
                      :disabled="agrupacionSelected == null"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-tooltip bottom max-width="20%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="primary--text" v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span
                        >Para seleccionar un grupo, debe eligir una
                        agrupación</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="2"
                    sm="6"
                    md="4"
                    class="d-flex py-0 justify-content-center"
                  >
                    <v-switch
                      v-model="allOrigen"
                      id="switch1"
                      :disabled="
                        agenteSelected == null ||
                          origenSelected != null ||
                          agrupacionSelected != null
                      "
                      class="mt-1"
                    ></v-switch>
                    <label for="switch1" class="mt-2"
                      >Todos los orígenes del agente</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Prestador -->
                  <v-col cols="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="prestadores-autocomplete"
                      v-model.trim="prestadoresSelected"
                      :items="prestadores"
                      :search-input.sync="prestadoresAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :disabled="
                        agenteSelected == null || allPrestadores == true
                      "
                      @change="prestadorChange(prestadoresSelected.id)"
                      :filter="customFilterPrestadores"
                      outlined
                      hide-no-data
                      hint="Ingrese matrícula o nombre del prestador"
                      clearable
                      cache-items
                      return-object
                      dense
                      autocomplete="off"
                      label="Prestador"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="prestadoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="2"
                    sm="6"
                    md="5"
                    class="d-flex py-0 justify-content-center"
                  >
                    <v-switch
                      v-model="allPrestadores"
                      id="switch2"
                      :disabled="
                        agenteSelected == null || prestadoresSelected != null
                      "
                      class="mt-1"
                    ></v-switch>
                    <label for="switch2" class="mt-2"
                      >Todos los prestadores del orígen y agente</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Convenios -->
                  <v-col cols="4" md="4" class="py-0">
                    <v-select
                      ref="convenio-autocomplete"
                      outlined
                      clearable
                      dense
                      :disabled="agenteSelected == null"
                      item-text="value"
                      :rules="[conveniosSelected.length != 0]"
                      item-value="id"
                      multiple
                      return-object
                      @change="
                        setPlanesByConvenios(),
                          conveniosSelected == null || conveniosSelected == []
                            ? $refs['planes-autocomplete'].reset()
                            : null
                      "
                      v-model="conveniosSelected"
                      label="Convenios (*)"
                      :items="convenios"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="conveniosToggle()">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                conveniosSelected.length > 0 ? 'primary' : ''
                              "
                            >
                              {{ multiselectIconConvenios }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ conveniosSelected.length - 1 }} otros)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- Planes -->
                  <v-col cols="4" md="4" class="py-0">
                    <v-select
                      ref="planes-autocomplete"
                      outlined
                      clearable
                      dense
                      item-text="value"
                      item-value="id"
                      multiple
                      :disabled="conveniosSelected.length == 0"
                      return-object
                      :loading="loadingPlanesByConvenio"
                      v-model="planesSelected"
                      label="Planes"
                      :items="planes"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="planesToggle()">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                planesSelected.length > 0 ? 'primary' : ''
                              "
                            >
                              {{ multiselectIconPlanes }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ planesSelected.length - 1 }} otros)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-1 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="1"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-col class="py-0" align="left">
                  <v-subheader>(*) Datos obligatorios</v-subheader>
                </v-col>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card class="mb-4">
          <v-data-table
            :headers="headers"
            item-key="idRegistro"
            v-model="registroSelected"
            :items="itemsAAsignar"
            show-select
            :items-per-page="20"
            :footer-props="{
              'items-per-page-options': [20, 40, 60, 80]
            }"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
            @toggle-select-all="selectAllToggle"
          >
            <template>
              <v-icon v-if="registroSelected.length > 0">
                mdi-checkbox-marked</v-icon
              >
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :readonly="item.existeOriPre == false"
                    :disabled="item.existeOriPre == false"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <span v-if="item.existeOriPre == false"
                  >No se puede seleccionar</span
                >
                <span v-else>Seleccionar registro</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.agenteNombre`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.agenteNombre }}</span
              >
            </template>
            <template v-slot:[`item.origenNombre`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.origenNombre }}</span
              >
            </template>
            <template v-slot:[`item.obraSocialNombre`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.obraSocialNombre }}</span
              >
            </template>
            <template v-slot:[`item.planNombre`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.planNombre }}</span
              >
            </template>
            <template v-slot:[`item.prestadorNombre`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.prestadorNombre }}</span
              >
            </template>
            <template v-slot:[`item.vigenciaHasta`]="{ item }">
              <span :style="!item.existeOriPre ? 'color:red' : ''">
                {{ item.vigenciaHasta }}</span
              >
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col col="8" class="text-right">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsAAsignar.length < 1"
                  @click="exportExcelModelo"
                >
                  Exportar lista completa
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card>
          <v-form
            v-model="actualizacionValida"
            ref="actualizacion-form"
            id="actualizacion-form"
            @submit.prevent="saveAsignar()"
          >
            <v-card>
              <v-col cols="12" class="py-0">
                <v-card-title class="primary--text pb-0">
                  Dato para asignación de tabla</v-card-title
                >
              </v-col>
              <v-row class="m-3">
                <v-col cols="8" md="3" class="py-0">
                  <v-menu
                    v-model="menuFechaDesde"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaSelectedDesde"
                        label="Nueva vigencia"
                        :append-icon="calendarIcon"
                        v-bind="attrs"
                        autocomplete="not"
                        clearable
                        hint="Formato DD/MM/AAAA"
                        @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                        :rules="rules.required.concat(rules.validDate)"
                        outlined
                        dense
                        v-mask="'##/##/####'"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaDesde"
                      no-title
                      @change="fechaSelectedDesde = formatDate(fechaDesde)"
                      @input="menuFechaDesde = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" class="text-right py-1">
                  <v-btn
                    align="end"
                    color="primary"
                    :disabled="
                      registroSelected.length < 1 || !actualizacionValida
                    "
                    :loading="loadingBtnSave"
                    type="submit"
                  >
                    Asignar tabla
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-14 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
        <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @onConfirm="exportar()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import moment from "moment";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import GoBackBtn from "@/components/shared/GoBackBtn";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "AsignacionTablaFac",
  components: {
    PageHeader,
    Ayuda,
    FiltersSelected,
    ConfirmDialog,
    GoBackBtn
  },
  directives: { mask },
  data() {
    return {
      agrupacionSelected: null,
      disabledCount: 0,
      openConfirmDialog: false,
      textConfirmDialog:
        "Existen tablas con vigencia posterior, ¿Desea exportar a excel?",
      actualizacionValida: false,
      itemsAgrupacion: [],
      grupoSelected: null,
      itemsGrupo: [],
      modalAddGrupos: false,
      planes: [],
      allOrigen: false,
      allPrestadores: false,
      conveniosSelected: [],
      convenios: [],
      planesSelected: [],
      optionCode: enums.webSiteOptions.CONSULTA_TABLA_FAC,
      banderaTrue: false,
      menuFechaVigencia: null,
      registroSelected: [],
      fechaVigencia: null,
      fechaDesde: null,
      fechaVigenciaSelected: "",
      infoIcon: enums.icons.SNB_INFO,
      origenSelected: null,
      itemsOrigen: [],
      itemsAAsignar: [],
      prestadoresSelected: null,
      prestadores: [],
      banderaPrestadorselected: false,
      prestadoresAutocompleteInput: null,
      prestadoresLoading: false,
      nombreTabla: "",
      title: enums.titles.ASIGNACION_TABLA_FAC,
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      fechaSelectedDesde: null,
      routeToGo: "ConsultaTablaFacturacion",
      verProc: enums.icons.SEE,
      menuFechaDesde: false,
      rules: rules,
      showExpand: false,
      showHelp: false,
      showFilters: true,
      filtersApplied: [],
      isFormValid: false,
      loading: false,
      agenteSelected: null,
      agentes: [],
      agentesAutocompleteInput: null,
      agentesLoading: false,
      banderaOrigenSelected: false,
      banderaAgentesSelected: false,
      origenesAutocompleteInput: null,
      search: "",
      infoNuevaActualizacion: null,
      showIcon: true,
      origenes: [],
      origenesLoading: false,
      headers: [
        {
          text: "Vigencia",
          value: "vigenciaHasta",
          sortable: false
        },
        {
          text: "Agente",
          value: "agenteNombre",
          sortable: false
        },
        {
          text: "Origen",
          value: "origenNombre",
          sortable: false
        },
        {
          text: "Prestador",
          value: "prestadorNombre",
          sortable: false
        },
        {
          text: "Convenio",
          value: "obraSocialNombre",
          sortable: false
        },
        {
          text: "Plan",
          value: "planNombre",
          sortable: false
        }
      ],
      cierreVigencia: null,
      loadingPlanesByConvenio: false,
      loadingBtnSave: false,
      filters: {},
      tablaParams: null,
      idTabla: null,
      tabVigenciaPost: null
    };
  },
  watch: {
    prestadoresAutocompleteInput(val) {
      if (val && val.length > 2) {
        this.getPrestadoresFilter();
      } else {
        this.$refs["prestadores-autocomplete"].cachedItems = [];
      }
    },
    origenesAutocompleteInput(val) {
      if (val) {
        this.getOrigenesFilter();
      }
    },
    allOrigen() {
      if (this.allOrigen == true) {
        this.agrupacionSelected = null;
        this.grupoSelected = null;
      }
    },
    agenteSelected() {
      if (this.agenteSelected == null) {
        this.allOrigen = false;
      }
    },
    agentesAutocompleteInput(val) {
      if (val) {
        this.getAgentesFilter();
        this.banderaTrue = true;
      } else {
        this.$refs["agente-autocomplete"].cachedItems = [];
      }
    }
  },
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.conveniosSelected &&
        this.conveniosSelected.length === this.convenios.length
      );
    },
    multiselectIconPlanes() {
      if (this.selectAllPlanes) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllPlanes() {
      return (
        this.planesSelected && this.planesSelected.length === this.planes.length
      );
    }
  },
  created() {
    this.cierreVigencia = this.$route.params.fechaVigencia;
    this.fechaVigenciaSelected = this.cierreVigencia
      ? this.cierreVigencia
      : null;
    this.tablaParams = this.$route.params.newTabla;
    this.titleTabla = this.tablaParams.tablaNombre;
    this.idTabla = this.tablaParams.tablaId;
    this.setSelects();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  methods: {
    ...mapActions({
      getAsignaciones: "prestadores/getAsignaciones",
      getConvenios: "afiliaciones/getConvenios",
      getPlanesByConvenioAndVigencia:
        "prestadores/getPlanesByConvenioAndVigencia",
      getAgentePagoByQuery: "prestadores/getAgentePagoByQuery",
      getAgrupaciones: "prestadores/getAgrupaciones",
      getGruposByAgrupaciones: "prestadores/getGruposByAgrupaciones",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresByQuery",
      fetchOrigenesNombreByQuery: "prestadores/fetchOrigenesNombreByQuery",
      saveAsignacionTablaFac: "prestadores/saveAsignacionTablaFac",
      setAlert: "user/setAlert"
    }),
    // actualizarCheckbox() {
    //   if(this.agenteSelected == null || this.agenteSelected == ''){
    //     this.allPrestadores = false
    //   }else {
    //     this.allPrestadores = true
    //   }
    // },
    // metodos de filtros
    async applyFilters() {
      if (
        this.origenSelected == null &&
        this.allOrigen == false &&
        this.grupoSelected == null
      ) {
        this.setAlert({
          type: "warning",
          message: "Ingrese un origen"
        });
      } else {
        this.loading = true;
        this.customizeFiltersApplied();
        this.filters = {
          planId: this.selectAllPlanes
            ? [-1]
            : this.planesSelected.map(x => x.id),
          osId: this.conveniosSelected.map(x => x.id),
          preId: !this.prestadoresSelected ? [0] : [this.prestadoresSelected],
          oriId: this.origenSelected ? [this.origenSelected] : [0],
          grupoId: this.grupoSelected ? this.grupoSelected.id : "",
          eligeOrigenesAgente: this.allOrigen,
          ageId: parseInt(this.agenteSelected.id),
          eligePrestadoresAgente: this.allPrestadores,
          vigencia: this.parseDateToIso(this.fechaVigenciaSelected)
        };
        try {
          const response = await this.getAsignaciones(this.filters);
          this.itemsAAsignar = response;
          this.itemsAAsignar.map(item => {
            if (item.existeOriPre == false) this.disabledCount += 1;
          });
          this.selectAllToggle();
          this.loading = false;
          this.showFilters = false;
        } catch {
          this.loading = false;
        }
      }
    },
    async saveAsignar() {
      this.disabledCount = 0;
      //JSON.stringify "lo uso para convertir el objeto a string"
      const stringObj = JSON.stringify(this.filters);
      this.loadingBtnSave = true;
      const data = {
        tabId: this.idTabla,
        vigencia: this.parseDateToIso(this.fechaSelectedDesde),
        filtrosAplicados: stringObj,
        asignaciones: this.registroSelected.map(x => ({
          agenteId: x.agenteId,
          oriId: x.oriId,
          preId: x.preId,
          osId: x.osId,
          planId: x.planId
        }))
      };
      try {
        const resp = await this.saveAsignacionTablaFac(data);
        this.tabVigenciaPost = resp["tablasVigenciaPosterior"];
        if (resp.error === 0 && this.tabVigenciaPost == 0) {
          this.setAlert({
            type: "success",
            message: resp.mensaje
          });
          this.loadingBtnSave = false;
          this.$router.push({
            name: "VerProcesoAsignacion"
          });
        } else if (resp.error > 0 && this.tabVigenciaPost.length > 0) {
          this.setAlert({
            type: "warning",
            message:
              "Se encontró más de una asignación posterior a la vigencia ingresada, no se pudo cerrar la vigencia"
          });
          this.exportar();
          this.loadingBtnSave = false;
        } else if (resp.error > 0 && this.tabVigenciaPost.length == 0) {
          this.setAlert({
            type: "warning",
            message: "Se generó un conflicto"
          });
          this.$router.push({
            name: "VerProcesoAsignacion"
          });
          this.loadingBtnSave = false;
        }
      } catch {
        this.loadingBtnSave = false;
      }
    },
    async setGruposByAgrupaciones() {
      this.grupoSelected = [];
      if (this.agrupacionSelected) {
        const grupos = await this.getGruposByAgrupaciones(
          this.agrupacionSelected
        );
        this.itemsGrupo = grupos;
      } else this.itemsGrupo = [];
    },
    async searchAgenteCta(codigoAgte) {
      const response = await this.getAgenteCuentaByCodigo({
        codigo: codigoAgte,
        nombre: "",
        dni: "",
        cuit: ""
      });
      const agenteResp = response[0];
      if (agenteResp) {
        return agenteResp;
      }
    },
    async setSelects() {
      const convenios = await this.getConvenios();
      this.convenios = convenios;
      const agrupaciones = await this.getAgrupaciones();
      this.itemsAgrupacion = agrupaciones;
    },
    exportar() {
      let result = [];
      this.tabVigenciaPost?.forEach(x =>
        result.push({
          ["Agente"]: x.agente,
          ["Origen"]: x.origen,
          ["Prestador"]: x.prestador,
          ["Convenio"]: x.convenio,
          ["Plan"]: x.plan,
          ["Tabla"]: x.tabla,
          ["Tabla posterior"]: x.tablaPosterior,
          ["Vigencia posterior"]: x.vigenciaPosterior
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Tablas con vigencias posteriores");
    },
    planesToggle() {
      this.$nextTick(() => {
        if (this.selectAllPlanes) {
          this.planesSelected = [];
        } else {
          this.planesSelected = this.planes;
        }
      });
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.conveniosSelected = [];
          this.planes = [];
          this.planesSelected = [];
        } else {
          this.conveniosSelected = this.convenios;
          this.setPlanesByConvenios();
        }
      });
    },
    changeformatDate(date) {
      var partes = date.split(" ");
      var fecha = partes[0]; // Obtiene solo la parte de la fecha
      var fechaPartes = fecha.split("/");
      var dia = fechaPartes[0];
      var mes = fechaPartes[1];
      var anio = fechaPartes[2];
      return dia + "/" + mes + "/" + anio;
    },
    async setPlanesByConvenios() {
      this.conveniosSelected;
      this.loadingPlanesByConvenio = true;
      if (!this.conveniosSelected) {
        this.planesSelected = [];
      } else {
        const idConvenios = this.conveniosSelected.map(x => x.id);
        const planes = await this.getPlanesByConvenioAndVigencia({
          vigencia: this.parseDateToIso(this.fechaVigenciaSelected),
          conveniosIds: idConvenios
        });
        this.planes = planes;
      }
      this.loadingPlanesByConvenio = false;
    },
    prestadorChange(data) {
      this.prestadoresSelected = data;
    },
    customFilterOrigen(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    customFilterPrestadores(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    customFilterAgente(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    origenChange(data) {
      this.origenSelected = data;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsAAsignar?.forEach(x =>
        result.push({
          ["Vigencia"]: x.vigenciaHasta,
          ["Agente"]: x.agenteNombre,
          ["Origen"]: x.origenNombre,
          ["Prestador"]: x.prestadorNombre,
          ["Convenio"]: x.obraSocialNombre,
          ["Plan"]: x.planNombre
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Asignación de tablas de facturación");
    },
    getPrestadoresFilter() {
      if (this.timerPrestadores) {
        clearTimeout(this.timerPrestadores);
        this.timerPrestadores = null;
      }
      this.timerPrestadores = setTimeout(async () => {
        if (
          this.prestadores?.some(
            x => x.value == this.prestadoresAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaPrestadorselected) {
          this.prestadoresLoading = true;
          const response = await this.fetchPrestadoresByQuery({
            input: this.prestadoresAutocompleteInput
          });
          this.$refs["prestadores-autocomplete"].cachedItems = [];
          this.prestadores = response;
          this.prestadoresLoading = false;
        } else {
          this.prestadoresLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchPrestadoresByQuery({
            input: this.infoNuevaActualizacion.preId.toString()
          });
          this.prestadores = response;
          this.prestadoresSelected = response[0].id;
          this.banderaPrestadorselected = false;
          this.prestadoresLoading = false;
        }
      }, 1000);
    },
    getAgentesFilter() {
      if (this.timerAgentes) {
        clearTimeout(this.timerAgentes);
        this.timerAgentes = null;
      }
      this.timerAgentes = setTimeout(async () => {
        if (this.agentes?.some(x => x.value == this.agentesAutocompleteInput)) {
          return;
        } else if (!this.banderaAgentesSelected) {
          this.agentesLoading = true;
          const response = await this.getAgentePagoByQuery({
            input: this.agentesAutocompleteInput
          });
          this.$refs["agente-autocomplete"].cachedItems = [];
          this.agentes = response;
          this.agentesLoading = false;
        } else {
          this.agentesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.getAgentePagoByQuery({
            input: this.infoNuevaActualizacion.ageId.toString()
          });
          this.agentes = response;
          this.agenteSelected = response[0].id;
          this.banderaAgentesSelected = false;
          this.agentesLoading = false;
        }
      }, 1000);
    },
    getOrigenesFilter() {
      if (this.timerOrigenes) {
        clearTimeout(this.timerOrigenes);
        this.timerOrigenes = null;
      }
      this.timerOrigenes = setTimeout(async () => {
        if (
          this.origenes?.some(x => x.value == this.origenesAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.origenes = response;
          this.origenesLoading = false;
        } else {
          this.origenesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.infoNuevaActualizacion.preId.toString()
          });
          this.origenes = response;
          this.origenSelected = response[0].id;
          this.banderaOrigenSelected = false;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    selectAllToggle() {
      if (this.itemsAAsignar.length === 0) {
        this.setAlert({
          type: "info",
          message:
            "Debe aplicar filtros para cargar la tabla y poder seleccionar algún registro"
        });
      } else if (this.itemsAAsignar.length == this.disabledCount) {
        this.registroSelected = [];
        this.setAlert({
          type: "info",
          message:
            "No se puede seleccionar ningún registro porque no existe relación entre origen y prestador"
        });
      } else {
        if (this.selectAllStatus) {
          // Si ya están todos seleccionados, deseleccionarlos
          this.registroSelected = [];
          this.setAlert({
            type: "info",
            message: "Todos los registros válidos han sido deseleccionados."
          });
        } else {
          // Si no están todos seleccionados, seleccionarlos
          this.registroSelected = this.itemsAAsignar.filter(
            item => item.existeOriPre
          );
          this.setAlert({
            type: "info",
            message: "Todos los registros válidos han sido seleccionados."
          });
        }
        // Cambiar el estado de selección
        this.selectAllStatus = !this.selectAllStatus;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaVigenciaSelected",
          label: "Fecha de consulta",
          model: this.fechaVigenciaSelected
        });
      }
      if (this.agentesAutocompleteInput) {
        this.filtersApplied.splice(1, 1, {
          key: "agentesAutocompleteInput",
          label: "Agente de pago",
          model: this.agentesAutocompleteInput
        });
      }
      if (this.origenesAutocompleteInput) {
        this.filtersApplied.splice(2, 1, {
          key: "origenesAutocompleteInput",
          label: "Origen",
          model: this.origenesAutocompleteInput
        });
      }
      if (this.grupoSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "grupoId",
          label: "Grupo",
          model: this.grupoSelected.value
        });
      }
      if (this.allOrigen) {
        const todosOrigenes = "SI";
        this.filtersApplied.splice(5, 1, {
          key: "allOrigen",
          label: "Todos los orígenes del agente",
          model: todosOrigenes
        });
      }
      if (this.prestadoresAutocompleteInput) {
        this.filtersApplied.splice(6, 1, {
          key: "prestadoresAutocompleteInput",
          label: "Prestador",
          model: this.prestadoresAutocompleteInput
        });
      }
      if (this.allPrestadores) {
        const todosPrestadores = "SI";
        this.filtersApplied.splice(7, 1, {
          key: "allPrestadores",
          label: "Todos los prestadores de orígen",
          model: todosPrestadores
        });
      }
      if (this.conveniosSelected) {
        this.filtersApplied.splice(8, 1, {
          key: "conveniosSelected",
          label: "Convenio",
          model: this.conveniosSelected
        });
      }
      if (this.planesSelected) {
        this.filtersApplied.splice(9, 1, {
          key: "planesSelected",
          label: "Planes",
          model: this.planesSelected
        });
      }
    },
    resetForm() {
      this.agenteSelected = null;
      this.origenSelected = null;
      this.fechaVigenciaSelected = null;
      this.nombreTabla = null;
      this.allOrigen = false;
      this.conveniosSelected = [];
      this.allPrestadores = false;
      this.agrupacionSelected = null;
      this.prestadoresSelected = null;
      this.planesSelected = [];
      this.grupoSelected = null;
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
